@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-text {
  padding: 0;
  &.centered {
    text-align: center;
  }
  &.narrow .ds-text {
    // I guess this could be modified to be narrower...
    @include breakpoint($desktop) {
      @include grid-column(13, $offset: 1);
    }
  }

  h1 {
    font-size: 30px;
    @include breakpoint($desktop) {
      font-size: 53px;
    }
    padding-bottom: 25px;
  }
  h2 {
    font-size: 20px;
    padding-bottom: 20px;

    @include breakpoint($desktop) {
      font-size: 40px;
      padding-bottom: 50px;
    }
  }
  &.narrow p {
    @include breakpoint($desktop) {
      width: 70%;
    }
  }

  &.centered p {
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-bottom: 40px;
    @include breakpoint($desktop) {
      line-height: 1.5em;
      font-size: 22px;
      margin-bottom: 50px;
    }
  }


  p:last-child {
    margin-bottom: 0;
  }

  .large-button {
    margin-top: 30px;
    display: inline-block;
    font-weight: 700;
  }
}
